import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useStyle from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {
    Container,
    Grid,
    MenuItem,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import Paragraph from "../../components/Typography/Paragraph";
import maintaincewhite from "../../assets/images/maintaincewhite.svg";
import maintainceorange from "../../assets/images/maintainceorange.svg";
import womansmile from "../../assets/images/womansmile.png";
import vectorbg from "../../assets/images/vectorbg.png";
import line from "../../assets/images/line.svg";
import team from "../../assets/images/team.svg";
import target from "../../assets/images/target.svg";
import serviceimage from "../../assets/images/serviceimage.png";
import yearofbusiness from "../../assets/images/yearofbusiness.svg";
import satisfyclient from "../../assets/images/satisfyclient.svg";
import dealcase from "../../assets/images/dealcase.svg";
import approvalrate from "../../assets/images/approvalrate.svg";
import step1 from "../../assets/images/step1.svg";
import step2 from "../../assets/images/step2.svg";
import step3 from "../../assets/images/step3.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import "./styles.css"
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import Step1 from "../../assets/images/Step1.png";
import Step2 from "../../assets/images/Step2.png";
import Step3 from "../../assets/images/Step3.png";
import Step4 from "../../assets/images/Step4.png";
import Step5 from "../../assets/images/Step5.png";
import Step6 from "../../assets/images/Step6.png";
import ArrowModal from "../../assets/images/arrow.svg";

const Home = () => {

    const classes = useStyle();
    const theme = useTheme();
    const { t } = useTranslation();

    const isMobile = window.innerWidth < 800;

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            '#ffffff',
        borderRadius: '0.75rem',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const [modalState, setModalState] = React.useState("app");

    const descriptionElementRef = React.useRef(null);

    const handleClickOpen = (e) => {
        window.location.replace("https://thehedgecapital.com/hform.apk")
        // setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
        setModalState("app")
    };

    const handleDownloadApk = () => {
        window.location.replace("https://thehedgecapital.com/hform.apk")
        // setModalState("tutorial")
    }

    return (
        <React.Fragment>
            <Navbar />
            <div style={{ overFlow: "hidden" }}>
                <div className={classes.theBannerBG}>

                    <Container maxWidth="sm">
                        <div className={classes.theTitleOne}>
                            <div className={classes.theContent}>
                                <Paragraph center size="48px" bold="500">
                                    {t("Hedge Fund")}<br />
                                    {t("Investment")} <br />
                                    {t("Micro-Financing")}
                                </Paragraph>

                                <div style={{ marginBottom: "3rem" }} />

                                <div className={classes.theApplyBTN}
                                    onClick={handleDownloadApk}
                                >
                                    <div>
                                        <img src={maintaincewhite} />
                                    </div>
                                    <Paragraph color="#FFFFFF" size="14px" bold="500" margin="0 0 0 0.5rem">
                                        {t("Download")}
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                    </Container>


                    <Container maxWidth="md">
                        <div className={classes.theMaintainanceContent}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <div className={classes.theBorderRight}>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            {t("Personal Financing")}
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            {t("Micro-Financing for personal loans")}
                                        </Paragraph>
                                    </div>

                                </Grid>


                                <Grid item xs={12} sm={12} md={4}>
                                    <div className={classes.theBorderRight}>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            {t("Corporate Financing")}
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            {t("Corporate financing for business loans, cashflow, invoice-financing")}
                                        </Paragraph>
                                    </div>

                                </Grid>


                                <Grid item xs={12} sm={12} md={4}>
                                    <div>
                                        <img src={maintainceorange} />
                                        <Paragraph color="#282828" size="18px" bold="600" margin="0.5rem 0 0 0">
                                            {t("Capital_Investments")}
                                        </Paragraph>
                                        <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                            {t("Capital investments to new businesses, or entreprenuers starting a new company")}
                                        </Paragraph>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>


                <div className={classes.theMarginBottom} />

                <div className={classes.theBannerBGTwo}>
                    <Container maxWidth="md">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} >
                                <div className={classes.theBannerTwoPosition}>
                                    <div>
                                        <img src={womansmile} />
                                    </div>
                                    <div className={classes.theWordPosition}>
                                        <img src={vectorbg} />
                                        <div className={classes.thePosition}>
                                            <div>
                                                <Paragraph color="#000000" size="18px" bold="600">
                                                    {t("Solve Your Finance Problems")}
                                                </Paragraph>
                                                <div>
                                                    <Paragraph color="#000000" size="12px" bold="400" margin="0.5rem 0 0 0">
                                                        {t("Talk to us and we will sort out your finance problems for you.")}
                                                    </Paragraph>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <div className={classes.thePaddingHome} id="about">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Paragraph color="#EA4843" size="18px" bold="600" margin="0 0.75rem 0 0">
                                            {t("About Us")}
                                        </Paragraph>
                                        <div>
                                            <img src={line} />
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: "2rem" }} />

                                    <Paragraph color="#000000" size="32px" bold="bold">
                                        {t("Hedge Capital Investments and Credit")}
                                    </Paragraph>

                                    <div style={{ marginBottom: "2rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400" style={{ lineHeight: "25px" }}>
                                        {t("Hedge Capital is an investment firm with more than 10 years in investments, personal loans and business loans. We provide customers credit loans with a manageable repayment scheme.")}
                                    </Paragraph>

                                    <div style={{ marginBottom: "2rem" }} />

                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <div>
                                                <img src={team} />
                                            </div>
                                            <Paragraph color="#000000" size="18px" bold="bold">
                                                {t("More than 3000 customers served")}
                                            </Paragraph>

                                            <div style={{ marginBottom: "1rem" }} />

                                            <Paragraph color="#000000" size="12px" bold="400" style={{ lineHeight: "25px" }}>
                                                {t("Hedge capital have served more than 3000 customers to date")}
                                            </Paragraph>

                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} >
                                            <div>
                                                <img src={target} />
                                            </div>
                                            <Paragraph color="#000000" size="18px" bold="bold">
                                                {t("Manageable Repayment Scheme")}
                                            </Paragraph>

                                            <div style={{ marginBottom: "1rem" }} />

                                            <Paragraph color="#000000" size="12px" bold="400" style={{ lineHeight: "25px" }}>
                                                {t("We provide customers with repayment schemes that will not be a burden to them.")}
                                            </Paragraph>

                                        </Grid>

                                    </Grid>

                                </div>

                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div style={{ padding: "1rem 0 2rem 0" }} id="service">
                    <Container maxWidth="md">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <img src={line} />
                            </div>
                            <Paragraph color="#EA4843" size="18px" bold="600" margin="0 0.75rem">
                                {t("Services")}
                            </Paragraph>
                            <div>
                                <img src={line} />
                            </div>
                        </div>

                        <div style={{ marginBottom: "1rem" }} />

                        <Paragraph center color="#000000" size="32px" bold="600">
                            {t("We Provide Flexible Credit Schemes")}
                        </Paragraph>

                        <div style={{ marginBottom: "1rem" }} />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={serviceimage} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        {t("Personal Loans")}
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        {t("Apply loans using your personal information. Suitable for working individuals or individuals that are just starting their business.")}
                                    </Paragraph>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={serviceimage} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        {t("Corporate Loans")}
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        {t("Apply loans using corporate or existing business. Suitable for companies than has more than 1 year history looking for adequet cashflow.")}
                                    </Paragraph>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} >
                                <div className={classes.theServiceContent}>
                                    <div className={classes.theServiceImg}>
                                        <img src={serviceimage} />
                                    </div>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="18px" bold="bold">
                                        {t("Capital Investments")}
                                    </Paragraph>

                                    <div style={{ marginBottom: "0.5rem" }} />

                                    <Paragraph color="#000000" size="12px" bold="400">
                                        {t("Hedge capital is open to invest in projects or companies that fits into our portfolio and criteria. Talk to us for more information.")}
                                    </Paragraph>
                                </div>

                            </Grid>

                        </Grid>

                    </Container>
                </div>

                <div className={classes.theBannerBGThree} id="team">
                    <Container maxWidth="md">
                        <div style={{ padding: "4rem 0" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={yearofbusiness} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                10
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            {t("Year of Business")}
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={satisfyclient} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                95%
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            {t("Satisfy Client")}
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={dealcase} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                3000+
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            {t("Deal Case")}
                                        </Paragraph>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <div>
                                        <div className={classes.theServiceType}>
                                            <div>
                                                <img src={approvalrate} />
                                            </div>

                                            <Paragraph color="#000000" size="32px" bold="500">
                                                95%
                                            </Paragraph>
                                        </div>

                                        <div style={{ marginBottom: "0.5rem" }} />

                                        <Paragraph center color="#FFFFFF" size="16px" bold="600">
                                            {t("Approval Rate")}
                                        </Paragraph>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>

                <div style={{ padding: "2rem 0" }} id="blog">
                    <Container maxWidth="md">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <img src={line} />
                            </div>
                            <Paragraph color="#EA4843" size="18px" bold="600" margin="0 0.75rem">
                                {t("How to Apply")}
                            </Paragraph>
                            <div>
                                <img src={line} />
                            </div>
                        </div>

                        <div style={{ marginBottom: "2rem" }} />

                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={4} >
                                <div className={classes.theApplyBox}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                            <Paragraph color="#000000" size="14px" bold="600">
                                                {t("STEP 1")}
                                            </Paragraph>
                                        </div>
                                        <div>
                                            <img src={step1} />
                                        </div>
                                    </div>

                                    <hr style={{ background: "#EA4843", margin: "0.75rem 0" }} />
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                        <Paragraph center color="#000000" size="14px" bold="400">
                                            {t("Download the HForm Application")}
                                        </Paragraph>

                                        {/* <div style={{ marginBottom: "2rem" }} /> */}

                                    </div>
                                </div>

                            </Grid>

                            <Grid item xs={12} sm={12} md={4} >
                                <div className={classes.theApplyBox}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                            <Paragraph color="#000000" size="14px" bold="600">
                                                {t("STEP 2")}
                                            </Paragraph>
                                        </div>
                                        <div>
                                            <img src={step2} />
                                        </div>
                                    </div>

                                    <hr style={{ background: "#EA4843", margin: "1.35rem 0" }} />
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                        <Paragraph center color="#000000" size="14px" bold="400">
                                            {t("Fill in all the information and submit the form to us")}
                                        </Paragraph>

                                        {/* <div style={{ marginBottom: "4.5rem" }} /> */}


                                    </div>
                                </div>

                            </Grid>

                            <Grid item xs={12} sm={12} md={4} >
                                <div className={classes.theApplyBox}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>
                                            <Paragraph color="#000000" size="14px" bold="600">
                                                {t("STEP 3")}
                                            </Paragraph>
                                        </div>
                                        <div>
                                            <img src={step3} />
                                        </div>
                                    </div>

                                    <hr style={{ background: "#EA4843", margin: "0.75rem 0" }} />
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                        <Paragraph center color="#000000" size="14px" bold="400">
                                            {t("Our professional agents will contact you for your application results")}
                                        </Paragraph>

                                        {/* <div style={{ marginBottom: "4.75rem" }} /> */}


                                    </div>
                                </div>

                            </Grid>

                        </Grid>

                        <div style={{ marginBottom: "2rem" }} />

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                            <div className={classes.theApplyBtnBtm}
                                onClick={handleDownloadApk}>
                                <div>
                                    <img src={maintaincewhite} />
                                </div>
                                <Paragraph color="#FFFFFF" size="14px" bold="500" margin="0 0 0 0.5rem">
                                    {t("Download")}
                                </Paragraph>
                            </div>
                        </div>

                    </Container>
                </div>

                <div className={classes.theBannerBGFour}>

                    <Container maxWidth="md">
                        <div style={{ padding: "2rem 0" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    <img src={line} />
                                </div>
                                <Paragraph color="#EA4843" size="18px" bold="600" margin="0 0.75rem">
                                    {t("FAQ's")}
                                </Paragraph>
                                <div>
                                    <img src={line} />
                                </div>
                            </div>
                            <div style={{ marginBottom: "2rem" }} />

                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            {t("Are we a legit credit company ?")}
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            {t("Hedge Capital is an investment firm that does investment primarily. We provide personal loans as well as part of our business.")}
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            {t("How is the repayment scheme like ?")}
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            {t("Upon sucessful application, our agents will plan out the repayment scheme with you to ensure that you are able to fulfill the repayment scheme without too much burden.")}
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            {t("What happens if I fail to meet the repayment scheme ?")}
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            {t("Our agents will communicate with the client to understand their situation and what can be adjusted to ensure both us and our client gets the maximum benefits.")}
                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Paragraph color="#000000" size="14px" bold="600">
                                            {t("How much is the interest rate ?")}
                                        </Paragraph>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paragraph color="#000000" size="12px" bold="400">
                                            {t("Interest rate depends on the individual, the submitted application and also the amount being loaned.")}

                                        </Paragraph>
                                    </AccordionDetails>
                                </Accordion>

                            </div>

                        </div>



                    </Container>
                </div>


            </div>
            <Footer />
            <Dialog
                onClose={handleClose}
                open={open}
                scroll={scroll}
                fullWidth={true}
                maxWidth={"xl"}
            >

                <div className={classes.theModal}>
                    {modalState == "app" ?
                        <React.Fragment>
                            <Paragraph center textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0.75rem">
                                {t("Mobile Application Apk")}
                            </Paragraph>
                            <div className={classes.theCloseModal} onClick={handleClose}>
                                <CloseIcon />
                            </div>

                            <div className={classes.theModalDownloadBtn} onClick={handleDownloadApk}>
                                <Paragraph center color="#FFFFFF" size="14px" bold="400">
                                    {t("Download")}
                                </Paragraph>
                            </div>
                        </React.Fragment>
                        :

                        <React.Fragment>
                            <Paragraph
                                center
                                textTransform="uppercase"
                                color="#EA4843"
                                size="18px"
                                bold="600"
                                margin="0 0 3rem"
                            >
                                {t("Mobile Application :User Manual")}
                            </Paragraph>
                            <div className={classes.theCloseModal} onClick={handleClose}>
                                <CloseIcon />
                            </div>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step1}
                                                alt={Step1}
                                            />
                                        </div>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step2}
                                                alt={Step2}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                {t("Step 1")}
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                {t("Allow the access of Unknown Sources on your phone setting")}
                                            </Paragraph>
                                        </div>
                                    </div>

                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step3}
                                                alt={Step3}
                                            />
                                        </div>
                                        <div className={classes.theArrowAbsolute}>
                                            <img
                                                src={ArrowModal}
                                                alt={ArrowModal}
                                            />
                                        </div>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step4}
                                                alt={Step4}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                {t("Step 2")}
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                {t("Open the application and Allow the access & Click “NEXT” button")}
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step5}
                                                alt={Step5}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                {t("Step 3")}
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                {t("Choose the Category")}
                                            </Paragraph>

                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; {t("For Business Man")}
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; {t("For Worker")}
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.theStep}>
                                    <div className={classes.TSImageBox}>
                                        <div className={classes.TSImage}>
                                            <img
                                                src={Step6}
                                                alt={Step6}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.theTextBox}>
                                        <div className={classes.theBorderText}>
                                            <Paragraph textTransform="uppercase" color="#EA4843" size="18px" bold="600" margin="0 0 10px">
                                                {t("Step 4")}
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; {t("Fill up all the information & upload all the document/pictures")}
                                            </Paragraph>
                                            <Paragraph textTransform="none" color="#000" size="16px" bold="400">
                                                &#9679; {t("Click the “Submit” button to submit")}
                                            </Paragraph>
                                        </div>
                                    </div>
                                </div>
                            </DialogContentText>
                        </React.Fragment>
                    }
                </div>
                <div className={classes.theModal}>

                    <div className={classes.theDownloadBtn}>
                        <a href="http://thehedgecapital.com/hform.apk" style={{ textDecoration: "none" }}>
                            <Paragraph center color="#FFFFFF" size="14px" bold="400">
                                {t("Download")}
                            </Paragraph>
                        </a>
                    </div>
                </div>
            </Dialog>
        </React.Fragment >
    )

}

export default Home;